<template>
	<form class="form form--registration form-registration" @submit.prevent="submitForm(fields)" autocomplete="off">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" v-model="fields.firstName" />
				<text-field class="form__field" label="Last Name" v-model="fields.lastName" />
				<text-field class="form__field" label="Email Address" type="email" autocomplete="email" v-model="fields.email" />
				<text-field class="form__field" label="Phone" v-model="fields.phone" />
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
			</div>
			<div class="form-row">
				<autocomplete-organization class="form__field" :value="fields.orgId" @selected="orgSelected" autocomplete="nope" :label="(fields.organization || {}).name" />
			</div>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Organization City" :value="fields.organization?.address?.city" :disabled="true" />
				<text-field class="form__field" label="Organization Stsate" :value="fields.organization?.address?.state" :disabled="true" />
				<checkbox class="form__field" label="Send Welcome Email" v-model="fields.sendMail" />
			</div>

			<h2 class="form-section__title">Permissions</h2>
			<div class="form-row">
				<checkbox class="form__field" v-for="(app, key) in applications" :key="`application_${key}`" name="permission" :label="app.name" v-model="fields.permissions[app.appId]" />
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Job Title" v-model="fields.settings.jobTitle" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading">Create User Account</a-button>

				<button class="button button--critical form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.regId">
					Remove Registration
				</button>
			</div>

		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { RegistrationStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { get, pick } from 'lodash'

export default {
	name: 'FormRegistration',
	components: {
		AButton,
		AutocompleteOrganization,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	props: {
		loading: Boolean,
		registration: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			address: {},
			permissions: {},
			organization: {},
			sendMail: true,
			settings: {},
		},
		states: stateList,
		fields: {
		},
		isLoading: false,
	}),
	computed: {
		organizations() {
			const orgs = this.$store.getters['organization/organizations']

			const organizations = []
			for (const org of orgs) {
				organizations.push({
					value: org.orgId,
					label: org.name,
				})
			}
			return organizations
		},
		applications() {
			return this.$store.getters['application/applications']
		},
		registrationStatus() {
			let status = []
			for (let key in RegistrationStatus) {
				status.push({
					value: key,
					label: RegistrationStatus[key],
				})
			}

			return status
		},
	},
	methods: {
		async submitForm(fields) {
			if (fields.regId) {
				this.isLoading = true
				try {
					const user = await this.$store.dispatch('registration/move', { regId: fields.regId, data: fields })
					if (user.uid) {
						this.$router.replace({ name: 'user', params: { uid: user.uid }})
						this.$notice(`User has been created!`)
					}
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
				this.isLoading = false
			}
		},
		remove(fields) {
			if (!fields.regId) return

			this.$confirm(`Are you sure you want to delete "${fields.firstName} ${fields.lastName}"?`, () => {
				this.isLoading = true
				this.$store.dispatch(`registration/remove`, fields.regId )
					.then(() => {
						this.$notice(`Registration removed!`)
						this.$router.back()
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			})
		},
		orgSelected(selected) {
			this.fields.orgId = selected.value
			this.fields.orgSearch = selected.label

			const orgs = this.$store.getters['organization/organizations']
			const org = orgs.find(o => o.orgId == selected.value)
			if (org) {
				this.fields.organization = org
			}
		}
	},
	watch: {
		registration: {
			immediate: true,
			handler: function (regProp) {
				this.fields = {
					...this.defaults,
					...pick(regProp, ['regId', 'address', 'email', 'firstName', 'lastName', 'orgId', 'organization', 'company', 'notes', 'permissions', 'phone', 'settings', 'status',]),
					...{ orgId: get(regProp, 'organization.orgId') },
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.form-registration {
}
</style>
